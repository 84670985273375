export const LinkTo = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2468 5.02679H3.01966V4H12.9999V13.9802H11.9731V5.75256L1.72605 15.9996L1 15.2736L11.2468 5.02679Z"
        fill={fill}
      />
    </svg>
  );
};

export const LinkToSmall = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53915 4.85566H2.68274V4H10.9997V12.3169H10.144V5.4609L1.60505 13.9999L1 13.3948L9.53915 4.85566Z"
        fill={fill}
      />
    </svg>
  );
};
